<template>
  <v-checkbox
  v-model="model"
  :label="label"
  :readonly="readonly"
  :disabled="disabled"
  :dense="dense"
  hide-details>
  </v-checkbox>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    value: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    dense: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      
    }
  }
}
</script>
<style>
</style>