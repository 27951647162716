<template>
  <v-tabs-items
  v-model="model"
  class="pb-1">
    <v-tab-item
    v-for="(c, i) in contents"
    :key="i">
      <component
      :is="c.componentName"
      v-bind="c.props"/>
    </v-tab-item>
  </v-tabs-items>
</template>
<script>
export default {
  components: {
    SimpleDataTable: () => import('@/components/SimpleDataTable'),
    BarChart: () => import('@/views/layout/component/BarChart')
  },
  props: {
    value: Number,
    contents: {
      type: Array,
      required: true
    }
  },
  model: {
    prop: "value",
    event: "valueChanged"
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit("valueChanged", v)
      }
    }
  }
}
</script>
<style>

</style>