<template>
  <div>
    <slot
    v-if="show"/>
    <div
    v-else
    :style="`width: 100%;`"
    class="d-flex justify-center align-center">
      <v-progress-linear
      indeterminate
      color="green"
      />
    </div>
  </div>  
</template>
<script>
export default {
  data: () => {
    return {
      show: true  
    }
  },
  methods: {
    refresh() {
      this.show = false
      setTimeout(() => this.show = true, 400)
    }
  }
}
</script>
<style>

</style>