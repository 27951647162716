<template>
  <v-tabs
  v-model="model"
  fixed-tabs
  class="base-tabs">
  <v-tab
  v-for="(t, i) in tabs"
  :key="i"
  :disabled="t.disabled">
    <span class="tab-header">{{t.title}}</span>
  </v-tab>
</v-tabs>
</template>
<script>
export default {
  props: {
    value: Number,
    tabs: {
      type: Array,
      required: true
    }
  },
  model: {
    prop: "value",
    event: "valueChanged"
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit("valueChanged", v)
      }
    }
  }
}
</script>
<style>
div.v-tabs.base-tabs > 
div.v-tabs-bar {
  height: 30px;
}
</style>