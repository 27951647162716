<template>
  <v-container
    class="dashboard"
    fluid >
    <v-row
    dense
    class="mt-0">
      <v-col
      v-if="isAdmin"
      cols="5">
        <v-autocomplete
          class="mr-5"
          v-model="selectedAccessId"
          :disabled="loading"
          :items="accounts"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="name"
          item-value="accessId"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedDateRange"
          :disabled="loading"
          :items="dateRange"
          color="primary"
          auto-select-first
          label="Date Range"
          item-text="label"
          item-value="value"
        />
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="from"
          v-model="search.fromModel"
          :return-value.sync="search.from"
          :disabled="loading || selectedDateRange != 'CUSTOM'"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.from"
              label="Date From"
              prepend-icon="mdi-calendar"
              :disabled="loading || selectedDateRange != 'CUSTOM'"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.from"
            :max="search.to"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.fromModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.from.save(search.from)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="to"
          v-model="search.toModel"
          :return-value.sync="search.to"
          :disabled="loading || selectedDateRange !== 'CUSTOM'"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.to"
              :disabled="loading || selectedDateRange !== 'CUSTOM'"
              label="Date To"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.to"
            :min="search.from"
            :max="maxDay"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.toModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.to.save(search.to)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="1" class="d-flex justify-start align-center"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="white--text btn-center mb-4"
          @click="fetchDashboard(selectedAccessId)"
        >
          Reload
          <v-icon
            dark
            right
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
    dense
    class="mt-0">
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12" class="mt-0">
            <h4 class="text-center chart-title mt-0 mb-1"><b>SMI Rates</b></h4>
            <v-data-table
              :headers="smiTableConfig.headers"
              :items="allChartData.smiRates.data"
              :disable-sort="false"
              :disable-pagination="true"
              :items-per-page="1000"
              :loading="loading"
              :disable="loading"
              :height="332"
              :item-class="smiTableItemClass"
              :fixed-header="true"
              hide-default-footer
              loading-text="Loading... Please wait"
              class="elevation-1 row-pointer headers--normal"
            >
              <template v-slot:item.value="{ item }">
                <span>{{ formatNumber(item.value) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-0">
            <h4 class="text-center chart-title mt-0 mb-1"><b>Upcoming dividends</b></h4>
            <v-data-table
              :headers="dividendsTableConfig.headers"
              :items="allChartData.dividends.data"
              :disable-sort="false"
              :disable-pagination="true"
              :items-per-page="1000"
              :item-class="() => 'slimTableRow'"
              :loading="loading"
              :disable="loading"
              :height="280"
              :fixed-header="true"
              hide-default-footer
              loading-text="Loading... Please wait"
              class="elevation-1 row-pointer headers--normal"
            >
              <template v-slot:item.value="{ item }">
                <span>{{ formatNumber(item.value) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12" class="mb-0 mt-0 pb-0">
            <h4 class="text-center chart-title mb-1 mt-0"><b>Top performers</b></h4>
          </v-col>
          <v-col cols="6" class="mt-0 pt-0">
            <v-data-table
              :headers="topPerformerConfig.lbdHeaders"
              :items="allChartData.topPerformer.lbd"
              :disable-sort="false"
              :disable-pagination="true"
              :items-per-page="5"
              :loading="loading"
              :disable="loading"
              :height="204"
              :fixed-header="true"
              :item-class="() => 'slimTableRow'"
              hide-default-footer
              loading-text="Loading... Please wait"
              class="elevation-1 row-pointer"
            />
          </v-col>
          <v-col cols="6" class="mt-0 pt-0">
            <v-data-table
              :headers="topPerformerConfig.mtdHeaders"
              :items="allChartData.topPerformer.mtd"
              :disable-sort="false"
              :disable-pagination="true"
              :items-per-page="5"
              :loading="loading"
              :disable="loading"
              :height="204"
              :fixed-header="true"
              :item-class="() => 'slimTableRow'"
              hide-default-footer
              loading-text="Loading... Please wait"
              class="elevation-1 row-pointer"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-0" >
            <h4 class="text-center chart-title mt-0 mb-1"><b>Calendar Events</b></h4>
            <tabs-container
            :data="getCalendarEventsData"
            height="378px"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
    class="mt-3">
      <v-col cols="12" lg="6" class="mt-0">
        <h4 class="text-center chart-title mt-0 mb-0" ><b>Historical Rates</b></h4>
        <v-row
        dense
        class="pr-2 my-1">
            <v-col
            cols="8">
              <v-text-field
                v-model="search.historical"
                :disabled="loading"
                label="Symbol search"
                filled
                clearable
                single-line
                hide-details
                @keyup.enter="tryToFetchHistoricalRates()"
                dense
              />
            </v-col>
            <v-col
            cols="4"
            class="d-flex align-end">
                    <v-autocomplete
                      v-model="search.historicalrange"
                      :disabled="loading"
                      :items="ratesRange"
                      color="primary"
                      auto-select-first
                      label="Range - One month"
                      item-text="name"
                      item-value="value"
                      dense
                      hide-details="auto"
                    />
              <v-btn
                :loading="loading"
                :disabled="loading || !search.historical"
                color="primary"
                class="white--text btn-center mr-0"
                @click="tryToFetchHistoricalRates()">
                Search
                <v-icon
                  dark
                  right>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
        <BarChart
        chartId="volume"
        :chartData="allChartData.historicalRates" />
      </v-col>
      <v-col cols="12" lg="6" class="mt-0">
        <yield-history-chart
        :loading.sync="loading"/>
      </v-col>
    </v-row>

    <v-row
    dense>
      <v-col cols="12" lg="6" class="mt-0">
        <h4 class="text-center chart-title mt-0 mb-0" ><b>P & L</b></h4>
        <BarChart
          chartId="volume"
          :chartData="allChartData.pnl"
          :chart-options="pnlOptions"
        />
      </v-col>
      <v-col cols="12" lg="6" class="mt-0">
        <h4 class="text-center chart-title mt-0 mb-0"><b>Daily Exposure</b></h4>
        <BarChart
          chartId="volume"
          :chartData="allChartData.exposure"
        />
      </v-col>
      <v-col cols="12" lg="6" class="mt-0">
        <h4 class="text-center chart-title mt-0 mb-0"><b>Daily Volume</b></h4>
        <BarChart
          chartId="volume"
          :chartData="allChartData.volume"
        />
      </v-col>
      <v-col v-if="allChartData.manager.labels" cols="12" lg="6" class="mt-0">
        <h4 class="text-center chart-title mt-0 mb-0"><b>Group Performance</b></h4>
        <BarChart
          chartId="volume"
          :chartData="allChartData.manager"
          :chart-options="pnlOptions"
        />
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import BarChart from '@/views/layout/component/BarChart'
import YieldHistoryChart from '@/components/dashboard/YieldHistoryChart'

import axios from 'axios'
import { mapGetters } from 'vuex'
import http from '@/shared/http'
import TabsContainer from '@/components/tabs/TabsContainer'

export default {
  name: 'Dashboard',
  components: {
    BarChart,
    TabsContainer,
    YieldHistoryChart
  },
  data: () => ({
    today: new Date(),
    selectedAccessId: -1,
    accounts: [],
    maxChartHeight: 300,
    smiTableConfig: {
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Symbol', value: 'symbol' },
        { text: 'Position', value: 'quantity' },
        { text: 'Rates (%)', value: 'value' },
        { text: 'Clearing', value: 'reportProvider' }
      ]
    },
    dividendsTableConfig: {
          headers: [
            { text: 'Last ex-date', value: 'lastExDate' },
            { text: 'Symbol', value: 'symbol' },
            { text: 'Position', value: 'position' },
            { text: 'Days to ex-date', value: 'daysToExDate' },
            { text: 'Dividend amount', value: 'dividendAmount' }
          ]
        },
    topPerformerConfig: {
      lbdHeaders: [
        { text: '#', value: 'number' },
        { text: 'Nickname', value: 'nickname' },
        { text: 'Total Δ (Last Day)', value: 'value' }
      ],
      mtdHeaders: [
        { text: '#', value: 'number' },
        { text: 'Nickname', value: 'nickname' },
        { text: 'Total Δ (MTD)', value: 'value' }
      ]
    },
    eventsTableConfig: {
      headers: [
        { text: 'Day', value: 'day', width: '1px', fixed: true },
        { text: 'Date', value: 'date', width: '120px' },
        { text: 'Time', value: 'time', width: '1px' },
        { text: 'Event', value: 'comment' }
      ]
    },
    selectedDateRange: 'NO_SELECTED', // default value
    dateRange: [{
      label: 'Last Business Day',
      value: 'LAST_BUSINESS_DAY'
    }, {
      label: 'This Week',
      value: 'THIS_WEEK'
    }, {
      label: 'Last Week',
      value: 'LAST_WEEK'
    }, {
      label: 'This Month',
      value: 'THIS_MONTH'
    }, {
      label: 'Last Month',
      value: 'LAST_MONTH'
    }, {
      label: 'Custom',
      value: 'CUSTOM'
    }],
    loading: false,
    search: {
      from: '',
      to: '',
      fromModel: false,
      toModel: false
    },
    ratesRange: [
        { name: 'One month', value: '1m' },
        { name: 'Six months', value: '6m' },
        { name: 'One year', value: '12m' }
      ],
    allChartData: {
      pnl: {},
      historicalRates: {},
      smiRates: {
        data: []
      },
      dividends: {
              data: []
            },
      topPerformer: {
        lbd: [],
        mtd: []
      },
      exposure: {},
      volume: {},
      manager: {},
      events: {
        nextWeek: [],
        thisWeek: []
      }
    },
    pnlOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          display: true,
          position: 'left',
          grid: {
            display: false
          }
        },
        y1: {
          type: 'linear',
          display: true,
          grid: {
            display: true
          },
          position: 'right'
        }
      }
    },
    historicalRatesOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: true,
              position: 'left',
              grid: {
                display: false
              }
            },
            y1: {
              type: 'linear',
              display: true,
              grid: {
                display: true
              },
              position: 'right'
            }
          }
        },

    pnl: {
      labels: [
        '2022-05-23',
        '2022-05-24',
        '2022-05-24',
        '2022-05-24',
        '2022-05-24',
        '2022-05-24'
      ],
      datasets: [
        {
          backgroundColor: '#48777d',
          label: 'me',
          data: [
            -884,
            3248,
            345,
            -545,
            45,
            365
          ],
          yAxisID: 'y'
        },
        {
          backgroundColor: '#4976da',
          label: 'firm average',
          data: [
            -166,
            106,
            345,
            -545,
            45,
            365
          ],
          yAxisID: 'y'
        },
        {
          data: [
            -884,
            2364,
            345,
            -545,
            45,
            365
          ],
          type: 'line',
          label: 'me(acum)',
          backgroundColor: '#ff4343',
          yAxisID: 'y1'
        },
        {
          data: [
            -166,
            -59,
            345,
            -545,
            45,
            365
          ],
          type: 'line',
          label: 'firm average(acum)',
          backgroundColor: '#d76c05',
          yAxisID: 'y1'
        }
      ],
    }
  }),
  computed: {
    getCalendarEventsData() {
      return {
        tabs: [
          {
            title: 'This Week',
            disabled: this.loading
          },
          {
            title: 'Next Week',
            disabled: this.loading
          }
        ],
        contents: [
          {
            componentName: 'SimpleDataTable',
            props: {
              "headers": this.eventsTableConfig.headers,
              "items": this.allChartData.events.thisWeek,
              "disableSort": false,
              "disablePagination": true,
              "itemsPerPage": 5,
              "loading": this.loading,
              "disabled": this.loading,
              "height": 378,
              "fixedHeader": true,
              "itemClass": this.eventItemClass,
              "hideDefaultFooter": true,
              "loadingText": "Loading... Please wait",
              "class": "elevation-1 row-pointer"
            }
          }, {
            componentName: 'SimpleDataTable',
            props: {
              "headers": this.eventsTableConfig.headers,
              "items": this.allChartData.events.nextWeek,
              "disableSort": false,
              "disablePagination": true,
              "itemsPerPage": 5,
              "loading": this.loading,
              "disabled": this.loading,
              "height": 375,
              "fixedHeader": true,
              "itemClass": this.eventItemClass,
              "hideDefaultFooter": true,
              "loadingText": "Loading... Please wait",
              "class": "elevation-1 row-pointer"
            }
          }
        ]
      }
    },
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    },
    ...mapGetters(['hasPermission', 'userInfo']),
    isAdmin () {
      return this.hasPermission('DASHBOARD_ADMIN')
    }
  },
  watch: {
    selectedDateRange: function (val) {
      this.processSelectDateRange(val)
      if (val !== 'CUSTOM') {
        this.fetchDashboard(this.selectedAccessId)
      }
    },
    selectedAccessId: function (val) {
      if (val) {
        this.fetchDashboard(val)
      }
    }
  },
  methods: {
    async fetchAccounts () {
      const response = await http.get('/api/v1/dashboard/accounts')
      if (response.status === 200) {
        this.accounts = response.data
        // this.selectedAccessId = this.accounts[0].accessId
      }
    },
    formatNumber (value) {
      return `(${(Math.round(Math.abs(value) * 100) / 100).toFixed(2)})`
    },
    smiTableItemClass: function (item) {
      return Math.abs(item.value) > 10 ? 'red-background slimTableRow' : 'green-background slimTableRow'
    },
    eventItemClass: function (item) {
      return item.isToday ? 'green-background slimTableRow' : 'slimTableRow'
    },
    formatDate (date) {
      const year = date.getFullYear()
      let month = date.getMonth()
      const day = date.getDate()

      month = month + 1
      return [
        year < 9 ? '0' + year : year,
        month < 10 ? '0' + month : month,
        day < 10 ? '0' + day : day].join('-')
    },
    processSelectDateRange (val) {
      this.selectedDateRange = val
      const today = new Date()
      const from = new Date()
      const to = new Date()
      switch (val) {
        case 'LAST_BUSINESS_DAY':
          // 0 - S => -2
          // 1 - M => -3
          // 2 - T => -1
          // 3 - W => -1
          // 4 - T => -1
          // 5 - F => -1
          // 6 - S => -1
          from.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          break
        case 'THIS_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1))
          break
        case 'LAST_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 13 : today.getDay() + 6))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : today.getDay()))
          // to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : 8 - today.getDay()))
          break
        case 'THIS_MONTH':
          from.setMonth(today.getMonth(), 1)
          // to.setMonth(today.getMonth() + 1, 0)
          break
        case 'LAST_MONTH':
          from.setMonth(today.getMonth() - 1, 1)
          to.setMonth(today.getMonth(), 0)
          break
        case 'CUSTOM':
        default:
      }

      this.search.from = this.formatDate(from)
      this.search.to = this.formatDate(to)
    },
    fetchDashboard (id) {
      const type = 'all'
      this.loading = true
      try {
        axios.get(`/api/v1/dashboard/${id}/${type}?start-date=${this.search.from}&end-date=${this.search.to}`)
          .then(r => {
            this.allChartData = r.data
            if (this.allChartData.smiRates.data.length !== 0) {
              this.allChartData.smiRates.data = this.allChartData.smiRates.data.sort((a, b) => b.value - a.value)
            }
            this.loading = false
          })
          .catch(e => {
            this.allChartData = {}
            this.loading = false
            console.error(e)
          })
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    async tryToFetchHistoricalRates() {
      try {
        this.loading = true
        const r = await axios.get(`/api/v1/dashboard/historical-rates/${this.search.historical}/${this.search.historicalrange}`)
        if (r.status === 200) {
          this.allChartData.historicalRates = r.data
        }
      }
      catch {
        this.allChartData.historicalRates = {}
      }
      finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.selectedDateRange = 'THIS_MONTH'
    this.processSelectDateRange(this.selectedDateRange)
    if (this.isAdmin) {
      this.fetchAccounts()
    }
  }
}
</script>
<style>
.dashboard h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.chart-title1 {
  text-transform: capitalize;
}

.dashboard .red-background {
  background-color: rgb(256, 0, 0, 0.2) !important;
}

.dashboard .red-background:hover {
  background-color: rgba(162, 0, 4, 0.5) !important;
}

.dashboard .green-background {
  background-color: rgba(0, 256, 0, 0.1) !important;
}

.dashboard .green-background:hover {
  background-color: rgba(0, 143, 6, 0.5) !important;
}

.slimTableRow td {
  height: 31px !important;
}

.dashboard.container {
  padding: 0px 2rem;
}

.v-data-table.headers--normal >
div.v-data-table__wrapper >
table > thead.v-data-table-header >
tr >
th {
  height: 30px;
  color: black;
}

</style>
