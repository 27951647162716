<template>
  <rerender
  ref="renderRef">
    <Bar
    :chart-options="options"
    :chart-data="source"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    />
  </rerender>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineController, PointElement, CategoryScale, LinearScale, LineElement } from 'chart.js'
import Rerender from '@/components/Rerender'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LineController, LinearScale, LineElement, PointElement)

export default {
  name: 'BarChart',
  components: {
    Bar,
    Rerender
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          datasets: []
        }
      }
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    source () { return this.$props.chartData },
    options () { return this.$props.chartOptions }
  },
  methods: {
    refresh() {
      this.$refs.renderRef.refresh()
    }
  }
}
</script>
