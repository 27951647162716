<template>
  <div
  :style="`height: ${height}`">
    <base-tabs
    v-model="tabProp"
    :tabs="data.tabs"/>
    <rerender
    ref="renderRef">
      <base-contents
      v-model="tabProp"
      :contents="data.contents"/>
    </rerender>
  </div>
</template>
<script>
import BaseTabs from '@/components/tabs/BaseTabs'
import BaseContents from '@/components/tabs/BaseContents'
import Rerender from '@/components/Rerender'
export default {
  components: {
    BaseTabs,
    BaseContents,
    Rerender
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    height: {
      type: String,
      default: "auto"
    },
    externalTab: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  computed: {
    tabProp: {
      get: function() {
        if (this.$props.externalTab !== null) return this.$props.externalTab
        else return this.tab
      },
      set: function(v) {
        if (this.$props.externalTab !== null) {
          this.$emit("update:externalTab", v)
        }
        else {
          this.tab = v
        }
      }
    }
  },
  data: () => {
    return {
      tab: 0
    }
  },
  methods: {
    refresh() {
      this.$refs.renderRef.refresh()
    }
  }
}
</script>
<style>

</style>