<template>
  <div>
    <h4 class="text-center chart-title mt-0 mb-0" ><b>Yield Curve</b></h4>
    <v-row
    dense
    class="pr-2 my-1">
        <v-col
        cols="8">
          <v-text-field
            v-model="searchValue"
            :disabled="loadingProp"
            label="Symbol search"
            filled
            clearable
            single-line
            hide-details
            @keyup.enter="tryToFetchYields()"
            dense
          />
        </v-col>
        <v-col
        cols="2">
          <text-field-check-box
          v-model="likeSearch"
          label="Partial search"
          dense
          :disabled="loadingProp"/>
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-end">
          <v-btn
            :loading="loadingProp"
            :disabled="isSearchBtnDisabled"
            color="primary"
            class="white--text btn-center mr-0"
            @click="tryToFetchYields()">
            Search
            <v-icon
              dark
              right>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
    </v-row>
    <div
    v-if="areThereYields">
      <BarChart
      ref="yieldsBarChartRef"
      chartId="volume"
      :chartData="yieldsChart"
      :chart-options="yieldsOptions"/>
    </div>
    <p
    v-else-if="yieldsRequested"
    class="text-center">
      NO BONDS
    </p>
  </div>
</template>
<script>
import axios from 'axios'
import BarChart from '@/views/layout/component/BarChart'
import TextFieldCheckBox from '@/components/textFields/TextFieldCheckBox'
export default {
  components: {
    BarChart,
    TextFieldCheckBox
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: null,
      yieldsChart: {
        labels: [],
        datasets: []
      },
      yieldsOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            position: 'left',
            max: 0, //this is not the real value, it will change dynamiclly.
            grid: {
              display: true
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                // console.log(context)
                const index = context[0].dataIndex
                const data = context[0].dataset.fullData[index]
                const couponRate = !data.couponRate || data.couponRate.length === 0 ? '--' : data.couponRate
                const moodyRating = !data.moodyRating || data.moodyRating.length === 0 ? '--' : data.moodyRating
                const snpRating = !data.snpRating || data.snpRating.length === 0 ? '--' : data.snpRating
                return `${data.maturityDate}\nyield: ${data.yield}\ncallable: ${data.callable}\ncoupon rate: ${couponRate}\nmoody rate: ${moodyRating}\nS&P rate: ${snpRating}`
              }
            }
          },
          legend: {
            display: true
          }
        }
      },
      yieldsRequested: false,
      datasetsStaticColors: [
        '#48777d',
        '#4976da',
        '#ff4343',
        '#d76c05',
        '#44a713'
      ],
      likeSearch: false
    }
  },
  computed: {
    loadingProp: {
      get: function() {
        return this.$props.loading
      },
      set: function(v) {
        this.$emit('update:loading', v)
      }
    },
    isSearchBtnDisabled() {
      return this.loadingProp || !this.searchValue || this.searchValue.length === 0
    },
    areThereYields() {
      if (this.datasets?.length > 0) {
        for (const dataset of this.datasets) {
          for (const entity of dataset.data) {
            if (!!entity.x) return true
          }
        }
      }
      return false
    },
    labels: {
      get: function() {
        return this.yieldsChart.labels
      },
      set: function(v) {
        this.yieldsChart.labels = v
      }
    },
    datasets: {
      get: function() {
        return this.yieldsChart.datasets
      },
      set: function(v) {
        this.yieldsChart.datasets = v
      }
    }
  },
  methods: {
    async tryToFetchYields() {
      try {
        this.loadingProp = true
        this.yieldsRequested = true
        const r = await axios.get(`/api/v1/corp-bond/yield/search/${this.searchValue}?likeSearch=${this.likeSearch}`)
        if (r.status === 200) {
          this.datasets = []
          this.labels = []
          const labelDates = []
          let yields = []
          for (const [k, v] of Object.entries(r.data)) {
            const { chartData, filteredData } = this.initYieldsChartConfigData(v, labelDates)
            yields = [...yields, ...chartData.map(i => i.y)]
            this.datasets.push({
                data: chartData,
                type: 'line',
                label: k,
                backgroundColor: this.datasets.length > this.datasetsStaticColors.length - 1 ? this.generateDatasetsDynamicColor() : this.datasetsStaticColors[this.datasets.length],
                yAxisID: 'y',
                fullData: filteredData,
                "pointRadius": 5
              })
          }
          this.yieldsOptions.scales.y.max = this.getMax(yields)
          this.labels = labelDates.sort((d1, d2) => d1.date - d2.date).map(i => i.value)
        }
      }
      catch(e) {
        console.error(e)
        this.datasets = []
      }
      finally {
        this.loadingProp = false
        this.refreshYieldsBarChartIfExists()
      }
    },
    generateDatasetsDynamicColor() {
      let color = `#${Math.floor(Math.random()*16777215).toString(16)}`
      if (this.datasetsStaticColors.includes(color)) color = this.generateDatasetsDynamicColor()
      return color
    },
    refreshYieldsBarChartIfExists() {
      if (!!this.$refs.yieldsBarChartRef) this.$refs.yieldsBarChartRef.refresh()
    },
    initYieldsChartConfigData(items, labelDates) {
      const currentYear = new Date(Date.now()).getFullYear()
      const endYear = currentYear + 30
      const chartData = []
      const filteredData = []

      for (const i of items) {
        const m = new RegExp(/^(?<year>\d{4})(-\d{2}){2}$/g).exec(i.maturityDate)
        const foundYear = Number.parseInt(m[1])
        if (currentYear > foundYear || foundYear > endYear) continue
        chartData.push({
          x: i.maturityDate,
          y: i.yield
        })
        filteredData.push(i)

        if (!this.isDateIn(labelDates, i.maturityDate)) labelDates.push({
          value: i.maturityDate,
          date: new Date(i.maturityDate)
        })
      }

      return { chartData, filteredData }
    },
    isDateIn(dates, dateStr) {
      for (const d of dates) {
        if (d.value === dateStr) return true
      }
      return false
    },
    getMax(values) {
      let max = 0
      let min = 0
      for (const v of values) {
        if (max < v) max = v
        if (min > v) min = v
      }
      const diff = max - min
      return diff < 10 ? Math.floor(max) * 2 : Math.floor(max) + 10
    }
  }
}
</script>
<style>

</style>